import { combineReducers } from 'redux';

import windowDimensionReducer from './windowDimensionReducer/windowDimensionReducer';
import newsReducer from './newsReducer/newsReducer';
import svgLoaderReducer from './svgLoaderReducer/svgLoaderReducer';

const rootReducer = combineReducers({
  windowRXS: windowDimensionReducer,
  newsRXS: newsReducer,
  svgLoaderRXS: svgLoaderReducer,
});

export default rootReducer;
