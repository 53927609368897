import React from 'react';

function useGsapLoaded() {
  const [isGsapLoaded, setGsapLoaded] = React.useState<boolean>(false);
  React.useEffect(() => {
    let timeoutId: number;

    // Function to check if GSAP is available in the window object
    const checkGsap = () => {
      if (window.gsap) {
        setGsapLoaded(true);
        clearTimeout(timeoutId); // Clear the timeout once GSAP is loaded
      } else {
        // Set a timeout to check again if GSAP is not loaded yet
        timeoutId = window.setTimeout(checkGsap, 100) as unknown as number;
      }
    };

    // Initial check for GSAP availability
    checkGsap();

    return () => {
      // Cleanup function to clear the timeout when the component is unmounted
      clearTimeout(timeoutId);
    };
  }, []);

  return isGsapLoaded;
}

export default useGsapLoaded;
