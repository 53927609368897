import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { RootStateTypes, TypedDispatch } from './store.types';
import rootReducer from '../reducers/reducers';

export const middleware = [thunk];

// Custom hooks
export const useAppDispatch = () => useDispatch<TypedDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStateTypes> = useSelector;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();

export const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

// https://github.com/reduxjs/redux-toolkit/issues/587
