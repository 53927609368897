// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const enum ActionTypes {
  // Browser
  CHANGE_WINDOW_DIMENSION_ON_RESIZE = 'CHANGE_WINDOW_DIMENSION_ON_RESIZE',

  // User location data
  SET_USER_LOCATION_DATA = 'SET_USER_LOCATION_DATA',

  // LOADER
  SET_TOTAL_ASSETS = 'SET_TOTAL_ASSETS',
  LOAD_SVG_START = 'LOAD_SVG_START',
  LOAD_SVG_COMPLETE = 'LOAD_SVG_COMPLETE',

  // NEWS ACTIONS
  NEWS_TOGGLE_LOADING = 'NEWS_TOGGLE_LOADING',
  FETCH_LATEST_NEWS_DATA = 'FETCH_LATEST_NEWS_DATA',
  FETCH_NEWS_DATA = 'FETCH_NEWS_DATA',
  FETCH_PAGINATED_NEWS_DATA = 'FETCH_PAGINATED_NEWS_DATA',
}
