import { ActionTypes } from '@src/redux/actions';
import { WindowDimensionDispatchTypes } from '@src/redux/actions/windowDimensionActions/windowDimensionActions.types';
import { DefaultStateTypes } from '@src/redux/reducers/windowDimensionReducer/windowDimensionReducer.types';

const defaultState: DefaultStateTypes = { width: 0, height: 0 };

const windowDimensionReducer = (state = defaultState, action: WindowDimensionDispatchTypes) => {
  switch (action.type) {
    case ActionTypes.CHANGE_WINDOW_DIMENSION_ON_RESIZE:
      return action.payload;
    default:
      return state;
  }
};

export default windowDimensionReducer;
